import React, { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
    Button,
    MenuItem,
    TextField,
    Typography,
    CircularProgress,
    Backdrop,
    Modal,
    Box,
} from "@mui/material";
import { zendeskTicketService } from "../../../services/zendeskTicketFeedbackService";
import { ZendeskFormInputs, ZendeskRequest, ZendeskResponse } from "../../../interfaces/zendeskTicketFeedbackInterfaces";
import { DataContext } from "../../../context/context";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { formValidations } from "../../../utils/validations/formValidations";

const subjectOptions = [
    { subject: "Visual Enhancement" },
    { subject: "Payment Issue" },
    { subject: "Account Inquiry" },
    { subject: "Email/Ebill Issue" },
    { subject: "Other" },
];

interface FeedbackFormModalProps {
    openModal: boolean;
    closeModal: () => void;
}

const FeedbackFormModal = ({ openModal, closeModal }: FeedbackFormModalProps) => {
    const { tenantData } = useContext(DataContext);
    const [isLoading, setIsLoading] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitFailed, setSubmitFailed] = useState(false);

    const handleClose = () => {
        if (submitFailed) { setSubmitFailed(false); }
        reset()
        closeModal();
    }

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<ZendeskFormInputs>({ mode: "all" });

    const onSubmit: SubmitHandler<ZendeskFormInputs> = async (data) => {
        setIsLoading(true);
        const feedbackRequest: ZendeskRequest = {
            ticket: {
                comment: { body: data.comment },
                priority: "normal",
                subject: "Quick Pay Feedback ( " + data.subjectType + " - " + tenantData.paymentInfo.tenantCode + " )",
                zendeskRequester: { name: tenantData.paymentInfo.lastName, email: tenantData.paymentInfo.email }
            }
        };

        try {
            const zendeskFeedbackResponse: ZendeskResponse = await zendeskTicketService.submitZendeskTicket(feedbackRequest, tenantData.authToken);

            if (zendeskFeedbackResponse.isSuccessful) {
                setIsLoading(false);
                setSubmitSuccess(true);

            } else {
                setIsLoading(false);
                setSubmitFailed(true);
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <Modal
                open={openModal}
                onClose={handleClose}
                style={{ overflow: "scroll" }}
            >
                <Box className="form-modal-content-wrapper">
                    <Typography id="feedback-title" variant="h6" component="h2" mb={2}>
                        Please Provide Feedback:
                    </Typography>
                    {!submitSuccess && !submitFailed && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                label="Name"
                                className={"textbox-width"}
                                autoComplete="off"
                                sx={{ mt: 2 }}
                                error={!!errors.name}
                                inputProps={{ maxLength: 75 }}
                                helperText={errors.name ? errors.name?.message : ""}
                                {...register("name", {
                                    required: "Name is required",
                                    maxLength: {
                                        value: 75,
                                        message: "Name exceeds maximum length",
                                    },
                                    validate: (nameOnCard) =>
                                        formValidations.nonAsciiCommasCheck(nameOnCard),
                                })}
                            />
                            <TextField
                                label="Email"
                                className={"textbox-width"}
                                autoComplete="off"
                                sx={{ mt: 2 }}
                                inputProps={{ maxLength: 75 }}
                                error={!!errors.email}
                                helperText={errors.email ? errors.email?.message : ""}
                                {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9}$/,
                                        message: "Invalid Email Address",
                                    },
                                    maxLength: {
                                        value: 75,
                                        message: "Email address exceeds maximum length",
                                    },
                                })}
                            />
                            <TextField
                                select
                                label="Subject Type"
                                className={"textbox-width"}
                                sx={{ textAlign: "left", mt: 2 }}
                                InputLabelProps={{ component: "div" }}
                                inputProps={register("subjectType", {
                                    required: "Subject Type is required",
                                })}
                                error={!!errors.subjectType}
                                helperText={errors.subjectType?.message}
                                defaultValue={""}
                            >
                                {subjectOptions.map((option) => (
                                    <MenuItem key={option.subject} value={option.subject}>
                                        {option.subject}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label="Comments"
                                multiline
                                className={"textbox-width"}
                                autoComplete="off"
                                sx={{ mt: 2 }}
                                error={!!errors.comment}
                                inputProps={{ maxLength: 150 }}
                                helperText={errors.comment ? errors.comment.message : ""}
                                {...register("comment", {
                                    required: "Please enter some feedback",
                                    maxLength: {
                                        value: 150,
                                        message: "Comment must be less than 150 characters",
                                    },
                                })}
                            />
                            <Box textAlign="center" mt={2} >
                                <Button
                                    disabled={isLoading}
                                    type="submit"
                                    sx={{ my: 2 }}
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                >
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    )}
                    {submitSuccess && (
                        <>
                            <CheckCircleOutline sx={{ color: 'green', fontSize: 40 }} />
                            <Typography variant="body1" color="primary">
                                Feedback submitted successfully!
                            </Typography>
                        </>
                    )}
                    {submitFailed && (
                        <>
                            <CancelOutlined sx={{ color: 'red', fontSize: 40 }} />
                            <Typography variant="body1" color="error">
                                Feedback submission failed! Please close the window and try again.
                            </Typography>
                        </>
                    )}
                    <Box textAlign="center">
                        <Button color="secondary" onClick={handleClose} sx={{ mt: 1 }}>
                            Close
                        </Button>
                    </Box>
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            </Modal>
        </>

    );
};

export default FeedbackFormModal;
